<template>
	<v-card class="px-6 mb-3">
		<v-toolbar color="transparent" elevation="0" class="px-0">
			<v-toolbar-title class="text-small">{{data.title}}</v-toolbar-title>
			<v-spacer></v-spacer>
			<span class="text-paragraph opacity-60">{{data.votes}} Votes • Poll closed since {{ days % 365 * -1 }}d, {{ hours % 24 * -1 }}h, {{ minutes % 60 * -1 }}m, {{ seconds % 60 * -1 }}s</span>
			
		</v-toolbar>
	</v-card>
</template>
<script>
export default {
	props: {
		data: Object
	},
	created() {
		this.endDate = new Date(this.data.datetime);
		setInterval(() => {
			const currentDate = new Date();
			const endTime = this.endDate - currentDate;
			this.seconds = parseInt(endTime / 1000);
			this.minutes = parseInt(this.seconds / 60);
			this.hours = parseInt(this.minutes / 60);
			this.days = parseInt(this.hours / 24);
			this.years = parseInt(this.days / 365);

		}, 1000);

        this.link = "/polls/"+this.data.slug;
	},
	data: () => ({
		years: "",
		days: "",
		hours: "",
		minutes: "",
		seconds: "",
		endDate: "",
        link: ""

	}),
}
</script>