<template>
	<v-row class="px-3 pl-md-0 pr-md-6 pt-6 pb-6">
		<v-col cols="12" md="6">
			<v-card class="pb-6">
				<v-card-text>
					<p class="text-normal mb-6">Set Up Poll</p>
					<p class="text-small">Poll Type</p>
					<v-radio-group v-model="data.type" class="mb-6" row>
						<v-radio color="secondary" v-for="(formtype, index) in formtypes" :key="index" :label="`${formtype.title}`" :value="formtype.slug"></v-radio>
					</v-radio-group>
					<div v-if="data.type == 'yes-or-no'">
						<p class="text-small mb-2 mt-2">Title</p>
						<v-text-field class="text-input" placeholder="Title" v-model="data.title"></v-text-field>
						<p class="text-small mb-2 mt-2">Question</p>
						<v-textarea class="mt-0" placeholder="Type here your poll question" v-model="data.question"></v-textarea>
					</div>
					<div v-if="data.type == 'multiple-choice'">
						<p class="text-small mb-2 mt-2">Title</p>
						<v-text-field class="text-input" placeholder="Title" v-model="data.title"></v-text-field>
						<p class="text-small mb-2 mt-2">Description</p>
						<v-textarea class="mt-0" placeholder="Type here your poll question" v-model="data.question"></v-textarea>
						<p class="text-small mb-2 mt-2">Choices</p>

						<v-text-field class="text-input" v-for="(choise, index) in choises" :key="index" :placeholder="`Option ${index + 1}`" v-model="choises[index]">
							<v-icon v-if="choises.length > 1" slot="append" color="white" @click="deleteChoice(index)"> mdi-delete-outline </v-icon>
						</v-text-field>
						<v-btn elevation="0" @click="createChoice()" append-icon="mdi-calender" class="text-dark px-0 mb-6" rounded color="systemwhite" text append>ADD OPTION<v-icon class="ml-2">mdi-plus-circle-outline</v-icon></v-btn>
					</div>
					<p class="text-small mb-2 mt-2">Voting period</p>
					<v-row>
						<v-col cols="12" md="6">
							<v-menu ref="menu" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field class="text-input" v-model="data.period.from" label="From" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
								</template>
								<v-date-picker  v-model="data.period.from" no-title scrollable></v-date-picker>
							</v-menu>
						</v-col>
						<v-col cols="12" md="6">
							<v-menu ref="menu" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field  class="text-input" v-model="data.period.to" label="Till" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
								</template>
								<v-date-picker :min="data.period.from" v-model="data.period.to" no-title scrollable> </v-date-picker>
							</v-menu>
						</v-col>
					</v-row>
					<v-btn elevation="0" class="text-dark mb-6" rounded color="systemwhite" @click="create()"  >CREATE POLL</v-btn>
					<!-- <v-btn elevation="0" class="text-dark mb-6" rounded color="systemwhite" @click="create()" :disabled="!connected">CREATE POLL</v-btn> -->

				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>
<script>
// import axios from 'axios';

export default {
	data: () => ({
		menu1: false,
		menu2: false,
		questioncount: 1,
		data: {
			type: "yes-or-no",
			title: "",
			question: "",
			txn: "",
			owner: "",
			period: {
				from: "",
				to: ""
			}
		},
		choises: [""],
		formtypes: [
			{
				title: "Yes or no",
				slug: 'yes-or-no'
			},
			{
				title: "Multiple Choice",
				slug: 'multiple-choice'
			}
		]
	}),
	props: {
		connected: Boolean,
		wallet: String
	},
	methods: {
		createChoice() {
			this.choises.push("");
		},
		deleteChoice(index) {
			this.choises.splice(index, 1);
		},
		create() {
			this.$toast.success(`Coming soon!`);
			// this.data.owner = this.wallet;
			// this.data.choises = this.choises;
			// axios.post("http://localhost/Crypto-strikers-api/v1/voting/0/create", this.data).then((res) => {
			// 	if (res.data.success) {
			// 		this.$toast.success(`Poll successfully created.`);
			// 	}else{
			// 		this.$toast.error(`Something went wrong. Could not create poll.`);
			// 	}
			// })
		}
	},
}
</script>