<template>
	<v-row class="px-3 pl-md-0 pr-md-6 pt-6 pb-6">
		<v-col cols="12" md="6">
			<v-card class="card py-6 px-6">
				<v-toolbar color="transparent" elevation="0" class="px-0">
					<span class="text-normal text-offwhite">Polls</span>
					<v-spacer></v-spacer>
					<v-btn elevation="0" class="text-dark" rounded color="systemwhite" to="/polls/create" :disabled="!connected">CREATE POLL</v-btn>
				</v-toolbar>
				<v-card-text class="px-0">
					<SubjectCard v-for="(poll, i) in polls" :key="i" :data="{ title: poll.title, desc: poll.data.question, slug: poll.reference, datetime: poll.enddate, votes: poll.awnser_count }" />
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12" md="6">
			<v-card class="card py-6 px-6">
				<v-toolbar color="transparent" elevation="0" class="px-0">
					<span class="text-normal text-offwhite">Past Polls</span>
				</v-toolbar>
				<v-card-text class="px-0">
					<SubjectCardSlim  v-for="(poll, i) in pollspast" :key="i" :data="{ title: poll.title, desc: poll.data.question, slug: poll.reference, datetime: poll.enddate, votes: poll.awnser_count }" />
					<p class="text-small-light opacity-60" v-if="pollspast.length == 0">There are no past polls</p>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>
<script>
import SubjectCard from "../../components/Subject.vue"
import SubjectCardSlim from "../../components/SubjectSlim.vue"
import axios from 'axios';

export default {
	components: {
		SubjectCard, SubjectCardSlim
	},
	props: {
		connected: Boolean,
	},
	data: () => ({
		ready: true,
		polls: [],
		pollspast: []

	}),
	mounted() {
		axios.get("https://api.crypto-strikers.com/v1/voting/list").then((res) => {
			res.data.data.forEach(poll => {
				if(poll.has_ended){
					this.pollspast.push(poll);
				}else{
					this.polls.push(poll);
				}
			});
		})

	}
}
</script>