<template>
	<v-row class="px-3 pl-md-0 pr-md-6 pt-6 pb-6">
		<v-col cols="12" md="6">
			<v-card class="pb-6">
				<v-card-text>
					<v-toolbar color="transparent" flat>
						<p class="text-normal mb-6">{{ data.title }}</p>
						<v-spacer></v-spacer>
						<v-btn elevation="0" class="text-dark my-6" rounded outlined color="secondary" :to="`${$route.params.id}/edit`" v-if="can_edit">EDIT</v-btn>
					</v-toolbar>
					<p class="text-small-light">{{ data.data.question }}</p>
					<span class="text-paragraph opacity-60">By {{ data.owner }} • {{ data.votes }} Votes • Poll closes in {{ days % 365 }}d, {{ hours % 24 }}h, {{ minutes % 60 }}m, {{ seconds % 60 }}s</span>

					<p class="text-small mb-3 mt-6">Current Standings</p>
					<div v-if="data.type == 'yes-or-no'">
						<div v-for="(option, index) in awnsers" :key="index">
							<div class="d-flex justify-space-between mb-1">
								<p class="mb-0">{{ option.label }}</p>
								<p class="mb-0 opacity-60">{{ option.percentage.toFixed(2) }}% ({{ option.value }} votes)</p>
							</div>

							<v-progress-linear class="mb-4" height="15" color="white" background-color="secondary" :value="option.percentage"></v-progress-linear>
						</div>
						<v-btn-toggle v-model="form.awnser" mandatory group class="d-block mt-6">
							<v-btn color="success" class="button-yes" value="Yes"> <v-icon>mdi-check</v-icon> <span class="ml-3">Yes</span> </v-btn>
							<v-btn color="error" class="button-no" value="No"> <v-icon>mdi-window-close</v-icon> <span class="ml-3">No</span> </v-btn>
						</v-btn-toggle>
					</div>

					<div v-if="data.type == 'multiple-choice'">
						<div v-for="(option, index) in awnsers" :key="index">
							<div class="d-flex justify-space-between mb-1">
								<p class="mb-0">{{ option.label }}</p>
								<p class="mb-0 opacity-60">{{ option.percentage.toFixed(2) }}% ({{ option.value }} votes)</p>
							</div>

							<v-progress-linear class="mb-4" height="15" color="white" background-color="secondary" :value="option.percentage"></v-progress-linear>
						</div>
						<v-radio-group v-model="form.awnser">
							<v-radio color="secondary" v-for="(choice, index) in data.data.choices" :key="index" :label="`${choice}`" :value="choice"></v-radio>
						</v-radio-group>
					</div>

					<!-- <v-btn elevation="0" class="text-dark my-6" rounded color="systemwhite" @click="vote()" :disabled="!connected">SUBMIT VOTE</v-btn> -->
					<v-btn elevation="0" class="text-dark my-6" rounded color="systemwhite" @click="vote()" disabled>SUBMIT VOTE</v-btn>

				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>
<script>
import axios from 'axios';

export default {
	data: () => ({
		menu1: false,
		menu2: false,
		questioncount: 1,
		can_edit: false,
		data: {
			type: "yes-or-no",
			title: "Test",
			owner: "0x",
			data: {
				question: "",
				owner: ""
			},
			votes: 0,
			period: {
				from: "",
				to: ""
			},
		},
		form: {
			awnser: ""
		},
		years: "",
		days: "",
		hours: "",
		minutes: "",
		seconds: "",
		endDate: "",
		options: [],
		awnsers: []
	}),
	props: {
		connected: Boolean,
		wallet: String
	},
	mounted() {
		axios.get("https://api.crypto-strikers.com/v1/voting/" + this.$route.params.id).then((res) => {
			this.data = res.data;
			this.endDate = new Date(this.data.enddate);
			if (this.data.type == "yes-or-no") {
				if (this.data.awnser_count == 0) {
					this.options.push({ percentage: 0, label: "Yes", value: 0 });
					this.options.push({ percentage: 0, label: "No", value: 0 });
				} else {
					this.data.awnsers.forEach(awnser => {
						this.awnsers.push({ percentage: (awnser.value / this.data.awnser_count * 100), label: awnser.label, value: awnser.value });
					});
				}
			}
			else if (this.data.type == "multiple-choice") {
				if (this.data.awnsers != undefined) {
					this.data.awnsers.forEach(awnser => {
						this.awnsers.push({ percentage: (awnser.value / this.data.awnser_count * 100), label: awnser.label, value: awnser.value });
					});
				}
			}

			setInterval(() => {
				const currentDate = new Date();
				const endTime = this.endDate - currentDate;
				this.seconds = parseInt(endTime / 1000);
				this.minutes = parseInt(this.seconds / 60);
				this.hours = parseInt(this.minutes / 60);
				this.days = parseInt(this.hours / 24);
				this.years = parseInt(this.days / 365);

			}, 1000);
		})

	},
	methods: {
		createChoice() {
			this.choises.push("");
		},
		deleteChoice(index) {
			this.choises.splice(index, 1);
		},
		vote() {
			this.form.address = this.wallet;
			this.form.txn = this.wallet;

			axios.post("https://api.crypto-strikers.com/v1/voting/" + this.$route.params.id + "/vote", this.form).then((res) => {
				if (res.data.success) {
					this.$toast.success(`Succesfully voted for polll.`);
				} else {
					this.$toast.error(`Something went wrong. Could not vote.`);
				}
			})
		}
	},
}
</script>